(function($){
  'use strict';

  var menuTrigger = $('.menu-trigger'),
  pageHeader = $('.page-header'),
  regExpNumber = new RegExp(/^\d+$/),
  portfolioTemplate = '<div class="portfolio-item">' +
  '<a href="#/{{URL}}" data-id="{{ID}}">' +
  '<div class="portfolio-item__cover" style="background-image: url(assets/{{THUMB}})"></div>' +
  '<div class="portfolio-item__content">' +
  '<h5>{{TITLE}}</h5>' +
  '</div>' +
  '</a>'+
  '</div>',
  projectModal = $('#project-modal'),
  projectModalContent = projectModal.find('.project-modal__content'),
  scrollVal,
  isRevealed,
  noscroll,
  isAnimating,
  docElem = window.document.documentElement;

  function getProgressLoad(){
    var number = parseInt( $('.pace-progress').attr('data-progress')) + 1;

    $('.preloader .logo-color').css('height',number+'%');
  }

  function preventDefault(e) {
    e = e || window.event;
    if (e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false;
    }
  }

  function isMobile(offset) {
    if (typeof offset === 'undefined'){
      offset = 0;
    }
    var winW = $(window).width();
    if (winW > (1024 + offset)) {
      return false;
    } else {
      return true;
    }
  }

  var clearProgress = setInterval(
    function(){
      getProgressLoad();
    }, 5);

  function scrollTo(offset, timeout) {
    $('html, body').animate({
      scrollTop: offset + 10
    }, timeout);
  }

  function toggleNav() {
    var target = event.target.className.search('menu-trigger__burger');

    if (target !== -1 && menuTrigger.hasClass('is-opened')) {
      menuTrigger.removeClass('is-opened');
      pageHeader.removeClass('is-opened');
    } else {
      menuTrigger.addClass('is-opened');
      pageHeader.addClass('is-opened');
    }
  }

  function loadPortfolio(opts) {
    opts = opts || {};
    var category = opts.category || 'todas',
    qty = opts.qty || 3,
    emptyContainer = opts.emptyContainer || false;

    var data = {
      'category': category,
      'quantity': qty
    };

    if (opts.id) {
      data['project_id'] = opts.id; // jshint ignore:line
      projectModal.addClass('is-opened');
    }

    $.ajax({
      url: 'api/load_portfolio.php',
      type: 'GET',
      dataType: 'json',
      data : data,
    })
    .done(function (res){

      if (res.html) { // Render project modal
        $('body').css('overflow', 'hidden').addClass('modal-opened');

        setTimeout(function() {
          window.scrollTo( 0, 0 );
          projectModalContent.html(res.html);
        }, 1000);

        setTimeout(function() {
          projectModal.addClass('is-loaded');
          projectModalContent.find('.project-detail__gallery-inner').slick({
            infinite: true,
            slidesToShow: 3,
            centerMode: true,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
            responsive: [
            {
              breakpoint: 770,
              settings: {
                slidesToShow: 1,
                arrows: false,
                dots: true
              }
            }
            ]
          });
        }, 1000);

      } else { // List projects
        var output = '';

        if (emptyContainer) {
          setTimeout(function() {
            $('.portfolio-container').empty();
          }, 200);
        }

        for (var i = 0; i < res.data.length; i++) {
          var item = res.data[i];

          output += portfolioTemplate
          .replace('{{ID}}', item.id)
          .replace('{{URL}}', item.url)
          .replace('{{THUMB}}', item.thumb)
          .replace(new RegExp('{{TITLE}}', 'g'), item.title);
        }

        if (!res.more && !emptyContainer) {
          $('.portfolio-more').addClass('no-more');
        } else {
          $('.portfolio-more').removeClass('no-more');
        }

        setTimeout(function() {
          $('.portfolio-container').append(output);
          var qtyItems = $('.portfolio-item').length;

          // Latest three items - Maybe a refact?
          TweenLite.set($('.portfolio-item').eq(qtyItems - 1), { opacity: 0, y: 100 });
          TweenLite.set($('.portfolio-item').eq(qtyItems - 2), { opacity: 0, y: 100 });
          TweenLite.set($('.portfolio-item').eq(qtyItems - 3), { opacity: 0, y: 100 });
          TweenLite.to($('.portfolio-item').eq(qtyItems - 1), 0.4, { opacity: 1, y: 0 });
          TweenLite.to($('.portfolio-item').eq(qtyItems - 2), 0.4, { opacity: 1, y: 0 });
          TweenLite.to($('.portfolio-item').eq(qtyItems - 3), 0.4, { opacity: 1, y: 0 });

        }, 400);
      }

    })
    .fail(function() {
      console.log('error');
    });
  }

  function initPortfolio() {
    var firstCat = $('.portfolio__categories a').first();
    firstCat.addClass('is-active');

    loadPortfolio();
  }

  function enableScroll() {
    if (window.removeEventListener) {
      window.removeEventListener('DOMMouseScroll', preventDefault, false);
    } else {
      window.onmousewheel = document.onmousewheel = null;
      window.onwheel = null;
      window.ontouchmove = null;
    }
  }

  function disableScroll() {
    if (window.addEventListener) {
      window.addEventListener('DOMMouseScroll', preventDefault, false);
    } else {
      window.onwheel = preventDefault;
      window.onmousewheel = document.onmousewheel = preventDefault;
      window.ontouchmove  = preventDefault;
    }
  }

  function animateHero(state) {
    if (state === 'show') {
      TweenLite.to($('.hero'), 0.2, {y: -$(window).height()});
      $('.content-container').addClass('show');
    } else if (state === 'hide') {
      TweenLite.to($('.hero'), 0.2, {y: 0});
      $('.content-container').removeClass('show');
    }
  }

  function toggle(reveal) {
    isAnimating = true;

    if (reveal) {
      animateHero('show');
    } else {
      noscroll = true;
      disableScroll();
      animateHero('hide');
    }

    /* simulating the end of the transition */
    setTimeout(function() {
      isRevealed = !isRevealed;
      isAnimating = false;
      if (reveal) {
        noscroll = false;
        enableScroll();
      }
    }, 1200);
  }

  function closeProject() {
    if (projectModal.hasClass('is-opened')) {
      projectModal.removeClass('is-opened');
      $('body').removeAttr('style').removeClass('modal-opened');

      noscroll = false;
      enableScroll();
      scrollTo($('#portfolio').offset().top, 0);

      setTimeout(function() {
        projectModalContent.empty();
        projectModal.removeClass('is-loaded');
      }, 800);
    }
  }

  function getUrlParam() {
    var params = window.location.hash.substring(2).split( '/' );
    if (!params.length) {
      return '';
    }

    return params;
  }

  /* detect if IE : from http://stackoverflow.com/a/16657946 */
  var ie = (function() {
    var undef, rv = -1, // Return value assumes failure.
    ua = window.navigator.userAgent,
    msie = ua.indexOf('MSIE '),
    trident = ua.indexOf('Trident/');

    if (msie > 0) {
      // IE 10 or older => return version number
      rv = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    } else if (trident > 0) {
      // IE 11 (or newer) => return version number
      var rvNum = ua.indexOf('rv:');
      rv = parseInt(ua.substring(rvNum + 3, ua.indexOf('.', rvNum)), 10);
    }

    return ((rv > -1) ? rv : undef);
  }());

  function scrollY() {
    return window.pageYOffset || docElem.scrollTop;
  }

  function scrollPage() {
    scrollVal = scrollY();

    if (noscroll && !ie) {
      if (scrollVal < 0) { return false; }
      window.scrollTo(0, 0);
    }

    if (isAnimating) {
      return false;
    }

    if (scrollVal <= 0 && isRevealed) {
      toggle(0);
    } else if (scrollVal > 0 && !isRevealed) {
      toggle(1);
    }
  }

  function goToSectionMenu(target) {
    event.preventDefault();

    menuTrigger.removeClass('is-opened');
    pageHeader.removeClass('is-opened');
    noscroll = false;
    enableScroll();

    scrollTo(target, 500);


    /*$('body').addClass('fade-out');

    setTimeout(function() {
      scrollTo(target, 0);
      $('body').removeClass('fade-out');
    }, 1000);*/
  }

  $(document).ready(function(){

   initPortfolio();

   if (getUrlParam()[0] === 'cases' && regExpNumber.test(getUrlParam()[1])) {
    var opts = {
      id: getUrlParam()[1]
    };

    loadPortfolio(opts);
  }

  $('#project-modal .project-modal__close, #project-modal .back').on('click', closeProject);

  $('.portfolio-container').on('click', 'a', function(event) {
    event.preventDefault();

    var id = $(this).data('id');
    var opts = {
      id: id
    };

    loadPortfolio(opts);
  });

  $('.project-modal__navigation .prev, .project-modal__navigation .next').click(function(event) {
    event.preventDefault();
    projectModal.animate({
      scrollTop: 0
    }, 1000);
    var id = Math.round(Math.random() * 100);
    var opts = {
      id: id
    };

    loadPortfolio(opts);

    setTimeout(function() {
      projectModal.removeClass('is-loaded');
    }, 200);
  });

  $('.portfolio-more').click(function() {
    var activeCat = $('.portfolio__categories a.is-active').data('category');

    var opts = {
      category: activeCat
    };

    loadPortfolio(opts);
  });

  $('.portfolio__categories a').click(function(event) {
    event.preventDefault();

    if(!$(this).hasClass('is-active')) {
      $('.portfolio__categories a').removeClass('is-active');
      $(this).addClass('is-active');
      var newCat = $(this).data('category');

      TweenLite.to($('.portfolio-item'), 0.2, { opacity: 0, y: 100 });

      var opts = {
        category: newCat,
        emptyContainer: true
      };

      loadPortfolio(opts);
    }
  });

  menuTrigger.on('click', toggleNav);

  $('.scrolldown').click(function() {
    toggle(1);
  });

  $('.scrollup').click(function() {
    scrollTo(0, 1000);
  });

  $('.main-menu a, .navigation__misc-project').click(function(event) {
    event.preventDefault();
    var target = $(this).attr('href');
    goToSectionMenu($(target).offset().top);
  });

  $('.services-scroller').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 1,
        arrows: false,
        dots: true
      }
    }
    ]
  });

  $('.clients-more__trigger').click(function() {
    var clientsListContainer = $('.clients-list');
    var clientsListContainerH = clientsListContainer.outerHeight();
    var clientH = clientsListContainer.find('.client').first().outerHeight();
    var totalClientsRow = Math.round(clientsListContainer.find('.client').length / 3);
    var clientsMaxHeight = clientH * totalClientsRow;

    if (clientsListContainerH < clientsMaxHeight) {
      clientsListContainer.height(clientsListContainerH + clientH);
    } else {
      $('.clients-more__trigger').parent().fadeOut();
    }

  });

  $('.blog-latest').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true
  });

  $('.input').on('blur', function() {
    var valLenght = $(this).val().trim().length;

    if (valLenght > 0) {
      $(this).addClass('filled');
    } else {
      $(this).removeClass('filled');
    }
  });

  $(document).on('mousemove','.hero', function(event){
    var frictionX = -1 * event.pageX / 50,
    frictionY = -1 * event.pageY / 50;

    TweenLite.to($('.hero .hero__bg'), 0.5, {css:{x:frictionX, y:frictionY}});
  });


  $('.section').each(function eachElement() {
    var $this = $(this);
    var position = $this.position();

    $this.scrollspy({
      min: position.top,
      max: position.top + $this.outerHeight() + 14,
      onEnter: function onEnter(element) {
        var id = $(element).attr('id');

        if (id === 'portfolio' || id === 'blog' || id === 'clients') {
          menuTrigger.addClass('dark');
        } else {
          menuTrigger.removeClass('dark');
        }
      }
    });
  });

});

  $(window).on('load', function() {

    setTimeout(function() {
      clearInterval(clearProgress);

      if (!$('body').hasClass('pace-done')) {
        setTimeout(function() {
          $('body').addClass('content-loaded');
        }, 500);
      } else {
        $('body').addClass('content-loaded');
      }

    //scrollTo(0, 0);

    if (!isMobile()) {
      window.addEventListener('scroll', scrollPage);

      var pageScroll = scrollY();
      noscroll = pageScroll === 0;

      disableScroll();

      if (pageScroll) {
        isRevealed = true;
        animateHero('show');
      }
    }

  }, 1500);
  });

})(jQuery);
